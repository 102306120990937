import {
  userDetail,
  noticeCount
} from "@/api/user";
import store from '@/store'

export default {
  install(Vue) {
    //这里是示例方法 getTime是方法名 function()可以携带参数
    Vue.prototype.getUserCom = function () {
      userDetail().then((res) => {
        localStorage.setItem("userInfo", JSON.stringify(res.data));
        store.state.user = res.data;
      });
    }
    Vue.prototype.noticeCountCom = function () {
      noticeCount(store.state.user.userId).then((res) => {
        store.state.MessageCount = res.data.count;
      });
    }
    Vue.prototype.setText = function (html) {
      return html
        .replace(/<(p|div)[^>]*>(<br\/?>|&nbsp;)<\/\1>/gi, '\n')
        .replace(/<br\/?>/gi, '\n')
        .replace(/<style[\s\S]*?<\/style>/gi, '')
        .replace(/<[^>/]+>/g, '')
        .replace(/(\n)?<\/([^>]+)>/g, '')
        .replace(/\u00a0/g, ' ')
        .replace(/&nbsp;/g, ' ')
        // eslint-disable-next-line no-useless-escape
        .replace(/<img loading="lazy"[^>]+src\\s*=\\s*['\"]([^'\"]+)['\"][^>]*>/g, '')
        .replace(/<\/?(img|table)[^>]*>/g, "") // 去除图片和表格
        .replace(/<\/?(a)[^>]*>/g, "") //  去除a标签
    }
  }
}
