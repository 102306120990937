import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import axios from 'axios'
import utils from './utils/config'
import md5 from 'js-md5'
import dataV from '@jiaminghi/data-view'
import {
  Pagination
} from 'element-ui';
import lang from 'element-ui/lib/locale/lang/en';
import locale from 'element-ui/lib/locale';
import BaiduMap from 'vue-baidu-map'
import VueMeta from 'vue-meta';

Vue.use(BaiduMap, {
  ak: 'CjyRuM3seEFNzZDvq5CgwDQYG36Fa1cp'
})
// 只在开发环境下初始化 VConsole
if (localStorage.getItem('language') === 'en') {
  locale.use(lang);
  Vue.use(Pagination);
}
Vue.use(VueMeta);
Vue.use(dataV)
Vue.config.productionTip = false
Vue.use(dataV)
ElementUI.Dialog.props.closeOnClickModal.default = false
Vue.prototype.$axios = axios
Vue.prototype.$utils = utils;
import common from './utils/common'
Vue.use(common);
Vue.prototype.$md5 = md5;
import {
  getUser,
  // insertUserAgent
} from "@/api/user";


ElementUI.Carousel.props.autoplay = {
  default: false,
  type: Boolean,
}


router.beforeEach((to, from, next) => {
  // console.log(to)

  if (to.query.code && !store.state.access_token) {
    let path = to.fullPath.split("?code")[0]
    if (to.query.language || to.query.id) {
      path = to.fullPath.split("&code")[0]
    }
    let data = {
      code: to.query.code,
      url: utils.baseURL + path,
    };
    console.log(data, 'data', to)
      console.log("---jiance getUser------")
      console.log(data)
    getUser(data)
      .then((res) => {
        localStorage.setItem("access_token", res.data.token);
        localStorage.setItem("Zyaccess_token", res.data.access_token);
        localStorage.setItem("idass", res.data.idass);
        localStorage.setItem("userId", res.data.userId);
        store.state.userId = res.data.userId;
        store.state.access_token = res.data.token;
        store.state.idass = res.data.idass
        let data = {
          userAgent: localStorage.getItem('murmur'),
          idass: store.state.idass,
        };
        // insertUserAgent(data)
        const insertUserAgent = Vue.prototype.$axios;
        insertUserAgent.post("https://www.oilgasinfoai.com/auth/oauth/insertUserAgent", data)
          .then((res) => {
            console.log(res)
            next({
              path: path,
            });
          })
        // window.location.replace(path);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  next();
})



Vue.use(ElementUI)
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
