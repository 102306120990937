<template>
  <div>
    <div v-if="model===false">
      <div ref="total" class="total">
        <div class="screen1">
          <headtip @showDialog="showPartner"></headtip>
          <!--      logo  you气通GPT社区 合作伙伴  登录-->
          <div class="up" v-if="scrollTrue===false" @click="uptop"></div>
          <!--       Geological Engineering GPTs 全球油气行业最专业的、开放的大模型人工智能云平台-->
          <div class="layer2">
            <div class="title1" style="cursor: pointer" @click="inter">Geological Engineering GPTs</div>
            <div class="title3" style="margin-top: 16vh;">
              <div class="hoverTitleup"  @click="position2">
                <div style="height: 1.5vw;width: 1.5vw;margin: 0.3vw 0.5vw"><img loading="lazy" src="@/assets/circlr.png">
                </div>
                Reservoir Geological Research<br> Technology GPTs
              </div>
              <div class="hoverTitleup"  style="width: 24vw">

                &nbsp; &nbsp; &nbsp; &nbsp;  &nbsp; &nbsp; &nbsp;  &nbsp; &nbsp;
              </div>
              <div class="hoverTitleup"  @click="position7" style="margin-right: 0;">
              <div style="height: 1.5vw;width: 1.5vw;margin: 0.3vw 0.5vw"><img loading="lazy" src="@/assets/circlr.png">
                </div>
                Oil & Gas Stimulation GPTs
              </div>

            </div>
            <div class="title3" style="margin: 16vh auto 0 auto;">
               <div class="hoverTitleup" style="margin-right: 32vw;"  @click="position6">
              <div style="height: 1.5vw;width: 1.5vw;margin: 0.3vw 0.5vw"><img loading="lazy" src="@/assets/circlr.png">
                </div>
                Engineering Geological <br>Technology GPTs
              </div>
              <div class="hoverTitleup" style="margin-right: -4vw"  @click="position3">
              <div style="height: 1.5vw;width: 1.5vw;margin: 0.3vw 0.5vw"><img loading="lazy" src="@/assets/circlr.png">
                </div>
                Increasing Production Overall <br>Solution Technical Services
              </div>

            </div>
            <div class="title3" style="margin-top: 12vh;">
              <div class="hoverTitleup" style="margin-right: 0"  @click="posHandle('generalAuto')">
                <div style="height: 1.5vw;width: 1.5vw;margin: 0.1vw 0.5vw; margin-right: .5vw;"><img loading="lazy" src="@/assets/circlr.png">
                </div>
                Dynamic Monitoring for Oil and Gas <br> Field Development GPTs
              </div>
            </div>
          </div>
          <!--      平台大图-->
          <div class="layer3"></div>
          <video src="@/assets/banner.mp4" loop autoplay muted preload="auto"  poster="@/assets/back1.jpg"></video>
        </div>
        <!--        Reservoir Geological Research Technology GPTs-->
        <div class="screenConsultnew" ref="generalMove">

          <div style="display: flex;margin:0 auto;">
            <div style="display: flex;margin:0 auto 0 auto;text-align: center;">
              <!--              <div style="height:2.2vw;text-align: right"><img loading="lazy" src="@/assets/icon_first.png">-->
              <!--              </div>-->
              <div class="title">&nbsp;Reservoir Geological Research Technology GPTs</div>
            </div>
          </div>
          <div class="light">
            The fine research of reservoir geology is oriented to the whole situation, focusing on the fine research of single well, with the combination of geological engineering as the characteristic research, providing customers with comprehensive geological research evaluation and development plan preparation services, and providing geological risk services for Anton's overall solution. At the same time, it has a number of characteristic technical services to carry out the fine research of reservoir geology in an all-round way. If you are interested in this, please
            <span  @click="clickConsult('Reservoir Geological Research <br> Technology GPTs')" style="color: rgb(245,114,21);cursor: pointer">contact Us</span>.
<!--            <span class="light2"><br>如想基于开放的通用性GPTs开展定制化的使用培训和开发，请联系我们，<span  @click="clickConsult('通用类GPTs')" style="color: #2F6EFF;cursor: pointer">contact Us</span>.</span>-->

          </div>
          <div class="pro3">

            <!--油田-->
            <div class="grand2" @click="comment">
              <div class="back">
                <div class="photo">
                  <img loading="lazy" style="border-top-left-radius: 9px;border-top-right-radius: 9px" src="@/assets/groundHome1.png"/>
                </div>
                <div class="title1">Reservoir comprehensive geological evaluation service
                </div>
              </div>
            </div>
            <!--        油藏-->
            <div class="grand2" @click="exploit">
              <div class="back">
                <div class="photo"><img loading="lazy" style="border-top-left-radius: 9px;border-top-right-radius: 9px" src="@/assets/groundHome2.png"/></div>
                <div class="title1">Hydrocarbon reservoir overall development scheme design service
                </div>

              </div>
            </div>

            <!--        智能-->
            <div class="grand2" @click="forecast">
              <div class="back">
                <div class="photo">
                  <img loading="lazy" style="border-top-left-radius: 9px;border-top-right-radius: 9px" src="@/assets/groundHome3.png"/>
                </div>
                <div class="title1">Characteristic technology of oil and gas reservoir research
                </div>


              </div>
            </div>

          </div>

        </div>
        <!--   Engineering Geological Technology GPTs-->
        <div class="screenGpts" ref="consultMove" >

          <div style="display: flex;margin:0 auto;">
            <div style="display: flex;margin:0 auto;text-align: center">
              <div class="title">&nbsp;Engineering Geology Technology GPTs</div>
            </div>
          </div>
          <div class="light">
            The engineering geological technologies GPTs centered on reservoir geological research, geophysical exploration, geomechanical modeling, sweet spot prediction and well trajectory optimization, real-time iterative optimization of drilling and fracturing process, fine reservoir description and remaining oil analysis, and tapping potential of old wells, covering the whole process of oil and gas field exploration and development research. If you are interested in this, please
            <span  @click="clickConsult('Engineering Geology Technology GPTs')" style="color: rgb(245,114,21);cursor: pointer">contact Us</span>.
            <!--            <span class="light2"><br>但由于泛化能力不足，仅提供案例和展示服务，供用户参考，如需进一步交流，请联系我们，<span  @click="clickConsult('Engineering Geological Technology GPTs')" style="color: #2F6EFF;cursor: pointer">contact Us</span>.</span>-->
          </div>
          <div class="pro3">
            <!--        智能-->
            <div class="grand2" @click="intelligent">
              <div class="back">
                <div class="photo">
                  <img style="border-top-left-radius: 9px;border-top-right-radius: 9px" loading="lazy" src="@/assets/groundHome8new.png"/>
                </div>
                <div class="title1">The intelligent engineering iterative optimization GPTs
                </div>
              </div>

            </div>
            <div class="grand2" @click="connect">
              <div class="back">
                <div class="photo">
                  <img style="border-top-left-radius: 9px;border-top-right-radius: 9px" loading="lazy" src="@/assets/groundHome6new.png"/>
                </div>
                <div class="title1">A series of techniques for tapping the potential of old wells combined with geological engineering

                </div>
              </div>

            </div>

            <div class="grand2" @click="special">
              <div class="back">
                <div class="photo">
                  <img style="border-top-left-radius: 9px;border-top-right-radius: 9px" loading="lazy" src="@/assets/groundHome7new.png"/>
                </div>
                <div class="title1">Special engineering technical service series

                </div>
              </div>

            </div>

          </div>


        </div>
        <!--    Oil & Gas Stimulation GPTs    -->
        <div ref="professional" class="screen3 screenGpts">
          <div class="title">Oil & Gas Stimulation GPTs</div>
          <div class="light" >
            TOil and gas stimulation technology GPTs has the ability to provide one-stop and comprehensive stimulation technology services from reservoir evaluation and design to on-site implementation, post-pressure evaluation, and production monitoring. It is committed to the research and industrial application of key stimulation technologies in the oil and gas field, and provides comprehensive solutions to the problems of oil and gas reservoir reconstruction. If you are interested in this, please
            <span  @click="clickConsult('Oil & Gas Stimulation GPTs')" style="color: rgb(245,114,21);cursor: pointer">contact Us</span>.
<!--            <span class="light2"><br>但由于对其他应用场景的泛化能力不足，如需更精准的专业应用，请联系我们，<span @click="clickConsult('Oil & Gas Stimulation GPTs')" style="color: #2F6EFF;cursor: pointer">contact Us</span>.</span>-->
          </div>
          <div class="pro3">
            <!--        油藏-->
            <div class="grand2" @click="single">
              <div class="back" style="background-color: #D4E4FF;">
                <div class="photo">
                  <img loading="lazy" src="@/assets/groundHome6.png"
                       style="border-top-right-radius: 9px;border-top-left-radius: 9px"/>
                </div>
                <div class="title1">Integrated production technology services and supporting technologies</div>
              </div>
            </div>
            <!--        智能-->
            <div class="grand2" @click="integration">
              <div class="back" style="background-color: #D4E4FF;">
                <div class="photo">
                  <img loading="lazy" src="@/assets/groundHome7.png"
                       style="border-top-right-radius: 9px;border-top-left-radius: 9px"/>
                </div>
                <div class="title1">Characteristic increasing product</div>
              </div>
            </div>
            <!--        智能-->
            <div class="grand2" @click="risk">
              <div class="back" style="background-color: #D4E4FF;">
                <div class="photo">
                  <img loading="lazy" src="@/assets/groundHome8.png"
                       style="border-top-right-radius: 9px;border-top-left-radius: 9px"/>
                </div>
                <div class="title1">Techniques for monitoring and evaluating the effect of stimulation measures</div>
              </div>
            </div>
          </div>

        </div>


        <!--Increasing Production Overall Solution Technical Services-->
        <div class="screen8" ref="aiPeixun">
          <div class="title">Increasing Production Overall Solution Technical Services</div>
          <div class="light" style=" ">
            Anton is committed to joint innovation with customers, closely combining geological engineering, precise engineering technology, lean operation management，providing customers with integrated overall solutions， relying on mature project management experience， advanced and applicable technology portfolio Value creation， through innovation to create breakthrough production to increase production and efficiency. If you are interested in this, please
            <span   @click="clickConsult('Increasing Production Overall Solution Technical Services')" style="color: rgb(245,114,21);cursor: pointer">contact Us</span>.
<!--            <span class="light2"><br>如您有培训或咨询需求，请联系我们，<span   @click="clickConsult('人工智能普及培训及转型服务')" style="color: #2F6EFF;cursor: pointer">contact Us</span>.</span>-->
          </div>
          <div class="pro2" @click="increase">
            <div style="width: 100%;height: fit-content">
              <img style="cursor:pointer;border-radius: 9px;width: 100%;height: fit-content" loading="lazy" src="@/assets/groundHome15.png"/>

            </div>


          </div>

        </div>

         <!--        油气田开发动态监测GPTs-->
         <div ref="generalAuto"   class="screenConsultnew">

          <div style="display: flex;margin:0 auto;">
            <div style="display: flex;margin:0 auto 0 auto;text-align: center;">
              <!--              <div style="height:2.2vw;text-align: right"><img loading="lazy" src="@/assets/icon_first.png">-->
              <!--              </div>-->
              <div class="title">&nbsp;Dynamic Monitoring for Oil and Gas Field Development GPTs</div>
            </div>
          </div>
          <div class="light">
            The dynamic monitoring GPTs for oil and gas field development feature fiber optic technology, offering reservoir geological evaluation and development engineering monitoring services that span the entire process of drilling, completion, and oil and gas production. This enables detailed evaluation and precise engineering control throughout the oil and gas development process. If you are interested in this, please <span  style="color: rgb(245,114,21);cursor: pointer" @click="clickConsult('Dynamic Monitoring GPTs <br> for Oil and Gas Field Development')">contact Us</span>。

          </div>
          <div class="pro3">

            <!--油田-->
            <div class="grand2" @click="distributevue">
              <div class="back">
                <div class="photo">
                  <img loading="lazy" src="@/assets/groundHome16.jpg" style="border-top-left-radius:9px;border-top-right-radius:9px; "/>
                </div>
                <div class="title1">Distributed Optical Fiber Real-time Monitoring Technology
                </div>
              </div>
            </div>
            <!--        油藏-->
            <div class="grand2" @click="gasreservoir">
              <div class="back">
                <div class="photo"><img loading="lazy" src="@/assets/groundHome15.jpeg" style="border-top-left-radius:9px;border-top-right-radius:9px;"/></div>
                <div class="title1">Reservoir Logging Technique
                </div>

              </div>
            </div>

            <!--        智能-->


            <!--        智能-->
            <div class="grand2"  @click="number">
              <div class="back">
                <div class="photo">
                  <img loading="lazy" src="@/assets/groundHome18.png" style="border-top-left-radius:9px;border-top-right-radius:9px;"/>
                </div>
                <div class="title1">Digital Rock Debris
                </div>
              </div>
            </div>
          </div>
          </div>


        <!--底部-->
        <div class="screen7">
          <bottomTip @showDialogBottom="scroll"></bottomTip>
        </div>

        <consultTip
            :dialogVisible="dialogVisible"
            :thisTitle="title"
            @hideDialog="hideDialog"
        ></consultTip>
        <partnerTip :dialogVisible="dialogconsult" @hideDialog="hide"></partnerTip>
      </div>
    </div>
    <div v-else>
      <div id="totalModel" ref="totalModel" class="totalModel">
        <div class="screen1">
          <headOther @showDialogTop="scroll"  @showDialog="showPartner"></headOther>
          <!--      logo  you气通GPT社区 合作伙伴  登录-->
          <video src="@/assets/banner.mp4" loop autoplay muted preload="auto"  poster="@/assets/back1.jpg"></video>
        </div>
        <div class="up" v-if="scrollTrue===false" @click="uptop"></div>

        <!--        Reservoir Geological Research Technology GPTs-->
        <div class="screenConsultnew" ref="generalMoveMobile">

          <div style="display: flex;margin:0 auto;">
            <div style="display: flex;margin:0 auto 0 auto;text-align: center;">
              <!--              <div style="height:2.2vw;text-align: right"><img loading="lazy" src="@/assets/icon_first.png">-->
              <!--              </div>-->
              <div class="title">&nbsp;Reservoir Geological Research Technology GPTs</div>
            </div>
          </div>
          <div class="light">
            The fine research of reservoir geology is oriented to the whole situation, focusing on the fine research of single well, with the combination of geological engineering as the characteristic research, providing customers with comprehensive geological research evaluation and development plan preparation services, and providing geological risk services for Anton's overall solution. At the same time, it has a number of characteristic technical services to carry out the fine research of reservoir geology in an all-round way. If you are interested in this, please <span  @click="clickConsult('Reservoir Geological Research <br> Technology GPTs')" style="color: rgb(245,114,21);cursor: pointer">contact Us</span>.
            <!--            <span class="light2"><br>如想基于开放的通用性GPTs开展定制化的使用培训和开发，请联系我们，<span  @click="clickConsult('通用类GPTs')" style="color: #2F6EFF;cursor: pointer">contact Us</span>.</span>-->

          </div>
          <div class="pro3">

            <!--油田-->
            <div class="grand2" @click="comment">
              <div class="back">
                <div class="photo">
                  <img loading="lazy" style="border-top-left-radius: 9px;border-top-right-radius: 9px" src="@/assets/groundHome1.png"/>
                </div>
                <div class="title1">Reservoir comprehensive geological evaluation service</div>
              </div>
            </div>
            <!--        油藏-->
            <div class="grand2" @click="exploit">
              <div class="back">
                <div class="photo"><img loading="lazy" style="border-top-left-radius: 9px;border-top-right-radius: 9px" src="@/assets/groundHome2.png"/></div>
                <div class="title1">Hydrocarbon reservoir overall development scheme design service</div>

              </div>
            </div>

            <!--        智能-->
            <div class="grand2" @click="forecast">
              <div class="back">
                <div class="photo">
                  <img loading="lazy" style="border-top-left-radius: 9px;border-top-right-radius: 9px" src="@/assets/groundHome3.png"/>
                </div>
                <div class="title1">Characteristic technology of oil and gas reservoir research</div>


              </div>
            </div>

          </div>

        </div>

        <div class="screenConsultnew" style="background-color: transparent;" ref="consultMoveMobile">

          <div style="display: flex;margin:0 auto;">
            <div style="display: flex;margin:0 auto 0 auto;text-align: center;">
              <!--              <div style="height:2.2vw;text-align: right"><img loading="lazy" src="@/assets/icon_first.png">-->
              <!--              </div>-->
              <div class="title">&nbsp;Engineering Geology Technology GPTs</div>
            </div>
          </div>
          <div class="light">
            The engineering geological technologies GPTs centered on reservoir geological research, geophysical exploration, geomechanical modeling, sweet spot prediction and well trajectory optimization, real-time iterative optimization of drilling and fracturing process, fine reservoir description and remaining oil analysis, and tapping potential of old wells, covering the whole process of oil and gas field exploration and development research. If you are interested in this, please <span  @click="clickConsult('Engineering Geology Technology GPTs')" style="color: rgb(245,114,21);cursor: pointer">contact Us</span>.
            <!--            <span class="light2"><br>如想基于开放的通用性GPTs开展定制化的使用培训和开发，请联系我们，<span  @click="clickConsult('通用类GPTs')" style="color: #2F6EFF;cursor: pointer">contact Us</span>.</span>-->

          </div>
          <div class="pro3">

            <!--油田-->
            <div class="grand2" @click="intelligent">
              <div class="back" style="background-color: #fff;">
                <div class="photo">
                  <img loading="lazy" style="border-top-left-radius: 9px;border-top-right-radius: 9px" src="@/assets/groundHome8new.png"/>
                </div>
                <div class="title1">The intelligent engineering iterative optimization GPTs</div>
              </div>
            </div>
            <!--        油藏-->
            <div class="grand2" @click="connect">
              <div class="back" style="background-color: #fff;">
                <div class="photo"><img loading="lazy" style="border-top-left-radius: 9px;border-top-right-radius: 9px" src="@/assets/groundHome6new.png"/></div>
                <div class="title1">A series of techniques for tapping the potential of old wells combined with geological engineering</div>

              </div>
            </div>

            <!--        智能-->
            <div class="grand2" @click="special">
              <div class="back" style="background-color: #fff;">
                <div class="photo">
                  <img loading="lazy" style="border-top-left-radius: 9px;border-top-right-radius: 9px" src="@/assets/groundHome7new.png"/>
                </div>
                <div class="title1">Special engineering technical service series</div>


              </div>
            </div>

          </div>

        </div>

        <div class="screenConsultnew"  ref="professionalMobile">

          <div style="display: flex;margin:0 auto;">
            <div style="display: flex;margin:0 auto 0 auto;text-align: center;">
              <!--              <div style="height:2.2vw;text-align: right"><img loading="lazy" src="@/assets/icon_first.png">-->
              <!--              </div>-->
              <div class="title">&nbsp;Oil & Gas Stimulation GPTs</div>
            </div>
          </div>
          <div class="light">
            TOil and gas stimulation technology GPTs has the ability to provide one-stop and comprehensive stimulation technology services from reservoir evaluation and design to on-site implementation, post-pressure evaluation, and production monitoring. It is committed to the research and industrial application of key stimulation technologies in the oil and gas field, and provides comprehensive solutions to the problems of oil and gas reservoir reconstruction. If you are interested in this, please <span  @click="clickConsult('Oil & Gas Stimulation GPTs')" style="color: rgb(245,114,21);cursor: pointer">contact Us</span>.
            <!--            <span class="light2"><br>如想基于开放的通用性GPTs开展定制化的使用培训和开发，请联系我们，<span  @click="clickConsult('通用类GPTs')" style="color: #2F6EFF;cursor: pointer">contact Us</span>.</span>-->

          </div>
          <div class="pro3">

            <!--油田-->
            <div class="grand2" @click="single">
              <div class="back">
                <div class="photo">
                  <img loading="lazy" style="border-top-left-radius: 9px;border-top-right-radius: 9px" src="@/assets/groundHome6.png"/>
                </div>
                <div class="title1">Integrated production technology services <br> and supporting technologies</div>
              </div>
            </div>
            <!--        油藏-->
            <div class="grand2" @click="integration">
              <div class="back">
                <div class="photo"><img loading="lazy" style="border-top-left-radius: 9px;border-top-right-radius: 9px" src="@/assets/groundHome7.png"/></div>
                <div class="title1">Characteristic increasing product</div>

              </div>
            </div>

            <!--        智能-->
            <div class="grand2" @click="risk">
              <div class="back">
                <div class="photo">
                  <img loading="lazy" style="border-top-left-radius: 9px;border-top-right-radius: 9px" src="@/assets/groundHome8.png"/>
                </div>
                <div class="title1">Techniques for monitoring and evaluating <br> the effect of stimulation measures</div>


              </div>
            </div>

          </div>

          </div>

        <!--Increasing Production Overall Solution Technical Services-->
        <div class="screen8" ref="aiPeixunMobile">
          <div class="title">Increasing Production Overall Solution Technical Services</div>
          <div class="light" style=" ">
            Anton is committed to joint innovation with customers, closely combining geological engineering, precise engineering technology, lean operation management，providing customers with integrated overall solutions， relying on mature project management experience， advanced and applicable technology portfolio Value creation， through innovation to create breakthrough production to increase production and efficiency.
            <span   @click="clickConsult('Increasing Production Overall Solution Technical Services')" style="color: rgb(245,114,21);cursor: pointer">contact Us</span>.
            <!--            <span class="light2"><br>如您有培训或咨询需求，请联系我们，<span   @click="clickConsult('人工智能普及培训及转型服务')" style="color: #2F6EFF;cursor: pointer">contact Us</span>.</span>-->
          </div>
          <div class="pro2" @click="increase">
            <div style="width: 100%;height: fit-content">
              <img style="cursor:pointer;border-radius: 9px;width: 100%;height: fit-content" loading="lazy" src="@/assets/groundHome15.png"/>

            </div>


          </div>

        </div>

        <div class="screenConsultnew"  ref="generalAutoMobile">

          <div style="display: flex;margin:0 auto;">
            <div style="display: flex;margin:0 auto 0 auto;text-align: center;">
              <!--              <div style="height:2.2vw;text-align: right"><img loading="lazy" src="@/assets/icon_first.png">-->
              <!--              </div>-->
              <div class="title">&nbsp;Dynamic Monitoring for Oil and Gas Field Development GPTs</div>
            </div>
          </div>
          <div class="light">
            The dynamic monitoring GPTs for oil and gas field development feature fiber optic technology, offering reservoir geological evaluation and development engineering monitoring services that span the entire process of drilling, completion, and oil and gas production. This enables detailed evaluation and precise engineering control throughout the oil and gas development process. If you are interested in this, please <span  @click="clickConsult('Dynamic Monitoring GPTs <br> for Oil and Gas Field Development')" style="color: rgb(245,114,21);cursor: pointer">contact Us</span>.
            <!--            <span class="light2"><br>如想基于开放的通用性GPTs开展定制化的使用培训和开发，请联系我们，<span  @click="clickConsult('通用类GPTs')" style="color: #2F6EFF;cursor: pointer">contact Us</span>.</span>-->

          </div>
          <div class="pro3">

            <!--油田-->
            <div class="grand2" @click="distributevue">
              <div class="back">
                <div class="photo">
                  <img loading="lazy" style="border-top-left-radius: 9px;border-top-right-radius: 9px" src="@/assets/groundHome16.jpg"/>
                </div>
                <div class="title1">Distributed Optical Fiber Real-time <br> Monitoring Technology</div>
              </div>
            </div>
            <!--        油藏-->
            <div class="grand2" @click="gasreservoir">
              <div class="back">
                <div class="photo"><img loading="lazy" style="border-top-left-radius: 9px;border-top-right-radius: 9px" src="@/assets/groundHome15.jpeg"/></div>
                <div class="title1">Reservoir Logging Technique</div>

              </div>
            </div>

            <!--        智能-->
            <div class="grand2" @click="number">
              <div class="back">
                <div class="photo">
                  <img loading="lazy" style="border-top-left-radius: 9px;border-top-right-radius: 9px" src="@/assets/groundHome18.png"/>
                </div>
                <div class="title1">Digital Rock Debris</div>


              </div>
            </div>

          </div>

          </div>
        <!--底部-->
        <div ref="bottom" class="screen7">
          <bottomTip @showDialogBottom="scroll"></bottomTip>
        </div>

        <consultTip
            :dialogVisible="dialogVisible"
            :thisTitle="title"
            @hideDialog="hideDialog"
        ></consultTip>
        <partnerTip :dialogVisible="dialogconsult" @hideDialog="hide"></partnerTip>
      </div>
    </div>
  </div>
</template>
<script>
import consultTip from "@/components/consultTip.vue";
import PartnerTip from "@/components/partnerTip.vue";
import headtip from "@/views/headTip.vue";
import headOther from "@/views/headOther.vue";
import bottomTip from "@/views/bottomTip.vue";
import {distribute} from "gsap/gsap-core";


export default {
  name: "Home",
  components: {
    bottomTip,
    PartnerTip,
    consultTip,
    headtip,
    headOther
  },

  data() {
    return {
      scrollPosition: null,
      model: false,
      avator: require("../assets/user.jpg"),
      userName: "",
      userId: null,
      dialogVisible: false,
      title:"数智油田",
      dialogconsult: false,
      product: 1,
      infogpt: 1,
      currentIndex: 0,
      itemsPerPage: 4,
      letters: '0123456789ABCDEF',
      carouselItems: [
        {
          img: require("../assets/logo1.png"),
          title: "智能钻井",
          text:
              "着陆段地质层分类预警：基于六个维度数据特征，建立最小AI模型，准确率提升20%。" +
              "<br>" +
              "水平段上下穿层预警：水平段穿层实时判断、上下切角实时预警建立最小模型。",
        },
        {
          img: require("../assets/logo2.png"),
          title: "智能压裂",
          text:
              "影响压裂效果的指标权重分析：影响压裂效果（EUR）的多维地质工程参数的指标权重分析。\n" +
              "<br>" +
              "压裂产量EUR预测：基于给定的多维地质工程参数，对EUR的AI预测模型准确率78%。",
        },
        {
          img: require("../assets/logo3.png"),
          title: "智能采油气",
          text: "磕头机的生产冲次辅助决策：构建智能采油气机器人提供辅助决策。",
        },
        {
          img: require("../assets/logo4.png"),
          title: "地层监测",
          text: "基于光纤的井筒完整性监测：基于光纤DTS温度数据建立对井筒故障监测及位置预警的最小模型。",
        },
        {
          img: require("../assets/logo5.png"),
          title: "设备设施监测",
          text: "设备故障检测：基于开源的滚动轴承数据建立模型，实时判断轴承故障类型、故障大小、故障位置",
        },
      ],
      groupedItems: [],
      scrollY: 0,
      scrollHeight:0,
      scrollTrue:true,
    };
  },

  computed: {
    // unconventional() {
    //   return unconventional
    // }
    // network() {
    //   return network
    // },
    // technology() {
    //   return technology
    // },

  },
  created() {
    localStorage.setItem("home", 1)
    // 通用GPT
    if (sessionStorage.getItem("ground") === '1') {
      this.$nextTick(() => {
        this.position2();
      });
    }
    // 油气资讯
    else if (sessionStorage.getItem("ground") === '2') {
      this.$nextTick(() => {
        this.position7();
      });
    }
    // 底部
    else if (sessionStorage.getItem("ground") === '3') {
      this.$nextTick(() => {
        this.position6();
      });
    }
    // 油气专业GPTs
    else if (sessionStorage.getItem("ground") === '4') {
      this.$nextTick(() => {
        this.position3();
      });
    }
    else if (sessionStorage.getItem("ground") === '6') {
      this.$nextTick(() => {
        this.posHandle('generalAuto')
      });
    }
    else if (sessionStorage.getItem("ground") === '0') {
      this.$nextTick(() => {
        this.uptop();
      });
    }

  },

  beforeDestroy() {

    this.$refs.total.removeEventListener('scroll', this.handleScroll);
  },
  mounted() {
    this.$refs.total.addEventListener('scroll', this.handleScroll);

    const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

    if (screenWidth <= 700) {
      this.model = true
    } else {
      this.model = false
    }

    // 通用GPT
    if (sessionStorage.getItem("skip") === '1') {
      this.$nextTick(() => {
        this.skipp1();
      });
    }
    // 油气资讯
    else if (sessionStorage.getItem("skip") === '2') {
      this.$nextTick(() => {
        this.skipp2();
      });
    }
    // 底部
    else if (sessionStorage.getItem("skip") === '3') {
      this.$nextTick(() => {
        this.skipp3();
      });
    }
    // 油气专业GPTs
    else if (sessionStorage.getItem("skip") === '4') {
      this.$nextTick(() => {
        this.skipp4();
      });
    }
    // 油气专业GPTs
    else if (sessionStorage.getItem("skip") === '5') {
      this.$nextTick(() => {
        this.skipp5();
      });
    }
    // 油气开发作业GPT应用服务
    else if (sessionStorage.getItem("skip") === '6') {
      this.$nextTick(() => {
        this.skipp6();
      });
    } else if (sessionStorage.getItem("skip") === '7') {
      this.$nextTick(() => {
        this.skipp7();
      });
    } else if (sessionStorage.getItem("skip") === '8') {
      this.$nextTick(() => {
        this.skipp8();
      });
    } else if (sessionStorage.getItem("skip") === '10') {
      this.$nextTick(() => {
        this.skipp10();
      });
    } else if (sessionStorage.getItem("skip") === '11') {
      this.$nextTick(() => {
        this.skipp11();
      });
    }else if (sessionStorage.getItem("skip") === '12') {
      this.$nextTick(() => {
        this.skipp12();
      });
    }else if (sessionStorage.getItem("skip") === '13') {
      this.$nextTick(() => {
        this.skipp13();
      });
    }else if (sessionStorage.getItem("skip") === '14') {
      this.$nextTick(() => {
        this.skipp14();
      });
    }
    // 添加滚动事件监听器
    this.updateGroupedItems();
  },

  methods: {
    posHandle(){
      let ref = this.model ? 'generalAutoMobile' : 'generalAuto'
      let id = this.model ? 'totalModel' : 'total'
      let element = this.$refs[ref];
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
      document.querySelector('.' + id)
      .scroll({
        top: rect,
        behavior: 'smooth'
      })
    },
    distribute() {
      return distribute
    },

    handleScroll() {
      if(this.model===false){
        this.scrollHeight = this.$refs.total.scrollTop;
      }
      else{
        this.scrollHeight = this.$refs.totalModel.scrollTop;
      }

      if( this.scrollHeight<100){
        this.scrollTrue=true
      }
      else{
        this.scrollTrue=false
      }
    },

    clickConsult(item){
      this.title=item
      this.dialogVisible = true;
      sessionStorage.setItem('title',item)
    },
    show() {
      localStorage.setItem("skip", 0)
      this.dialogconsult = true;
    },
    comment(){
      window.open("/geoevaluation", "_blank");
    },
    exploit(){
      window.open("/schemedesign", "_blank");
    },
    intelligent(){
      window.open("/intelligentengineering", "_blank");
    },
    connect(){
      window.open("/potentialtapping", "_blank");
    },
    special(){
      window.open("/specialengineering", "_blank");
    },
    forecast(){
      window.open("/featuretech", "_blank");
    },
    ground(){
      window.open("/ground", "_blank");
    },
    modeling(){
      window.open("/modeling", "_blank");
    },
    single(){
      window.open("/integratedstimulation", "_blank");
    },
    integration(){
      window.open("/featuredproducts", "_blank");
    },
    risk(){
      window.open("/stimulationmonitoring", "_blank");
    },
    unconventional() {
      window.open("/unconventional", "_blank");
    },
    drill() {
      window.open("/drill", "_blank");
    },
    realtime() {
      window.open("/realtime", "_blank");
    },
    area() {
      window.open("/area", "_blank");
    },
    unite() {
      window.open("/unite", "_blank");
    },
    scene() {
      window.open("/scene", "_blank");
    },
    increase() {
      window.open("/increase", "_blank");
    },
    distributevue(){
      window.open("/FiberOptic", "_blank");
    },
    gasreservoir(){
      window.open("/logging", "_blank");
    },
    number(){
      window.open("/digital", "_blank");
    },


    gpts() {
      localStorage.setItem("skip", 0)
      window.open("https://www.oilgascommunity.com/recently?id=39", "_blank");
    },
    // 油藏地质
    // ground() {
    //   localStorage.setItem("skip", 0)
    //   window.open("/groundOil", "_blank");
    // },
    oilgpt() {
      localStorage.setItem("skip", 0)
      window.open("/gptOil", "_blank");
    },
    // 钻井
    hole() {
      localStorage.setItem("skip", 0)
      window.open("/makeHole", "_blank");
    },
    oilDesign() {
      localStorage.setItem("skip", 0)
      window.open("/gptDesign", "_blank");
    },
    oilTranslate() {
      localStorage.setItem("skip", 0)
      window.open("/gptTranslate", "_blank");
    },
    oilTechnology() {
      localStorage.setItem("skip", 0)
      window.open("/gptTechnology", "_blank");
    },
    oilgpt2() {
      localStorage.setItem("skip", 0)
      window.open("/gptTranslate", "_blank");
    },
    // 压裂
    frack() {
      localStorage.setItem("skip", 0)
      window.open("/frackOil", "_blank");
    },
    oilgpt3() {
      localStorage.setItem("skip", 0)
      window.open("/gptHole", "_blank");
    },
    // 采油
    getOil() {
      localStorage.setItem("skip", 0)
      window.open("/getOil", "_blank");
    },

    // <!--设备检测-->
    equipment() {
      localStorage.setItem("skip", 0)
      window.open("/monitorEqu", "_blank");
    },

    // 地层监测
    monitor() {
      localStorage.setItem("skip", 0)
      window.open("/monitorOil", "_blank");
    },

    // 油田管理
    menage() {
      localStorage.setItem("skip", 0)
      window.open("/menageOil", "_blank");
    },

    station() {
      localStorage.setItem("skip", 0)
      window.open("/station", "_blank");
    },
    security() {
      localStorage.setItem("skip", 0)
      window.open("/security", "_blank");
    },
    productANxun() {
      localStorage.setItem("skip", 0)
      window.open("/product", "_blank");
    },
    equipmentAnxun() {
      localStorage.setItem("skip", 0)
      window.open("/equipmentAnxun", "_blank");
    },
    technologyAnxun() {
      localStorage.setItem("skip", 0)
      window.open("/technology", "_blank");
    },
    monitorAnxun() {
      localStorage.setItem("skip", 0)
      window.open("/monitorAnxun", "_blank");
    },
    networkAnxun() {
      localStorage.setItem("skip", 0)
      window.open("/network", "_blank");
    },
    basic() {
      localStorage.setItem("skip", 0)
      window.open("/basic", "_blank");
    },
    setting() {
      localStorage.setItem("skip", 0)
      window.open("/setting", "_blank");
    },
    traning() {
      const element = this.$refs.aiPeixun;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);


      this.$nextTick(() => {
        this.$refs.total.scrollTop = rect
      });
    },
    governance(){
      window.open("/governance", "_blank");
    },
    analysis(){
      window.open("/analysis", "_blank");
    },
    traningAi(){
      window.open("/traning", "_blank");
    },

    consult() {
      window.open("/consult", "_blank");
    },
    ERP(){
      window.open("/ERP", "_blank");
    },
    java(){
      window.open("/java", "_blank");
    },
    web(){
      window.open("/web", "_blank");
    },
    service() {
      const element = this.$refs.oilZixun;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

      this.$nextTick(() => {
        this.$refs.total.scrollTop = rect
      });
    },
    meitiProduct(){
      window.open("http://www.oilmates-omm.com/h-col-106.html", "_blank");
    },
    meiti3D(){
      window.open("http://www.oilmates-omm.com/h-col-102.html", "_blank");
    },
    meitiDesign(){
      window.open("http://www.oilmates-omm.com/h-col-104.html", "_blank");
    },
    construct() {
      const element = this.$refs.companyZijian;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);


      this.$nextTick(() => {
        this.$refs.total.scrollTop = rect
      });
    },
    constructPlat(){
      window.open("/construct", "_blank");
    },
    business() {
      window.open("/business", "_blank");
    },
    company() {
      window.open("/company", "_blank");
    },
    computingPower() {
      window.open("/computingPower", "_blank");
    },

    menageService() {
      const element = this.$refs.menageService;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);


      this.$nextTick(() => {
        this.$refs.total.scrollTop = rect
      });
    },
    application() {
      window.open("/application", "_blank");
    },
    oilApplication() {
      window.open("/oilApplication", "_blank");
    },

    knowledge() {
      window.open("/gptKnowledge", "_blank");
    },
    data() {
      window.open("/gptData", "_blank");
    },
    wisdom() {
      window.open("/wisdom", "_blank");
    },
    plat() {
      window.open("/plat", "_blank");
    },
    design() {
      window.open("/design", "_blank");
    },
    automation() {
      window.open("/automation", "_blank");
    },

    traning1() {
      sessionStorage.setItem("skip", 11)
      this.$router.push("/traning")
    },
    service1() {
      sessionStorage.setItem("skip", 11)
      this.$router.push("/service")
    },
    construct1() {
      sessionStorage.setItem("skip", 11)
      this.$router.push("/construct")
    },
    menageService1() {
      const element = this.$refs.menageServices;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);


      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },
    application1() {
      sessionStorage.setItem("skip", 11)
      this.$router.push("/application")
    },
    oilApplication1() {
      sessionStorage.setItem("skip", 11)
      this.$router.push("/oilApplication")
    },

    knowledge1() {
      sessionStorage.setItem("skip", 10)
      this.$router.push("/gptKnowledge")
    },
    data1() {
      this.$router.push("/gptData")
      sessionStorage.setItem("skip", 10)
    },
    wisdom1() {
      sessionStorage.setItem("skip", 10)
      this.$router.push("/wisdom");
    },
    plat1() {
      sessionStorage.setItem("skip", 10)
      this.$router.push("/plat")
    },
    design1() {
      sessionStorage.setItem("skip", 10)
      this.$router.push("/design")
    },
    automation1() {
      sessionStorage.setItem("skip", 10)
      this.$router.push("/automation")
    },

    gpts1() {
      window.open("https://www.oilgascommunity.com/recently?id=39", "_blank");
    },
    // 油藏地质
    ground1() {
      sessionStorage.setItem("skip", 6)
      this.$router.push("/groundOil")
    },
    oilgpt1() {
      sessionStorage.setItem("skip", 5)
      this.$router.push("/gptOil")
    },
    // 钻井
    hole1() {
      sessionStorage.setItem("skip", 6)
      this.$router.push("/makeHole")
    },

    oilgpt21() {
      sessionStorage.setItem("skip", 5)
      this.$router.push("/gptTranslate")
    },
    // 压裂
    frack1() {
      sessionStorage.setItem("skip", 6)
      this.$router.push("/frackOil")
    },
    oilgpt31() {
      sessionStorage.setItem("skip", 5)
      this.$router.push("/gptHole")
    },
    // 采油
    getOil1() {
      sessionStorage.setItem("skip", 6)
      this.$router.push("/getOil")
    },

    // <!--设备检测-->
    equipment1() {
      sessionStorage.setItem("skip", 6)
      this.$router.push("/monitorEqu")
    },

    // 地层监测
    monitor1() {
      sessionStorage.setItem("skip", 6)
      this.$router.push("/monitorOil")
    },

    // 油田管理
    menage1() {
      sessionStorage.setItem("skip", 6)
      this.$router.push("/menageOil")
    },

    station1() {
      sessionStorage.setItem("skip", 7)
      this.$router.push("/station")
    },
    security1() {
      sessionStorage.setItem("skip", 7)
      this.$router.push("/security")
    },
    productANxun1() {
      sessionStorage.setItem("skip", 7)
      this.$router.push("/product")
    },
    equipmentAnxun1() {
      sessionStorage.setItem("skip", 7)
      this.$router.push("/equipmentAnxun")
    },
    technologyAnxun1() {
      sessionStorage.setItem("skip", 7)
      this.$router.push("/technology")
    },
    monitorAnxun1() {
      sessionStorage.setItem("skip", 8)
      this.$router.push("/monitorAnxun")
    },
    networkAnxun1() {
      sessionStorage.setItem("skip", 8)
      this.$router.push("/network")
    },
    basic1() {
      sessionStorage.setItem("skip", 8)
      this.$router.push("/basic")
    },
    setting1() {
      sessionStorage.setItem("skip", 8)
      this.$router.push("/setting")
    },
    showPartner() {
      localStorage.setItem("skip", 0)
      this.dialogconsult = true;
    },
    scroll(value){
      console.log(value)
      // 通用GPT
      if (value== 1) {
        this.$nextTick(() => {
          this.position2();
        });
      }
      else if (value == 2) {
        this.$nextTick(() => {
          this.position7();
        });
      }
      else if (value == 3) {
        this.$nextTick(() => {
          this.position6();
        });
      }
      else if (value == 4) {
        this.$nextTick(() => {
          this.position3();
        });
      }
      else if (value == 6) {
        this.$nextTick(() => {
          this.posHandle('generalAuto')
        });
      }
      else if (value == 0) {
        this.$nextTick(() => {
          this.uptop();
        });
      }
    },
    scrollskip(value){
      console.log(value)
      // 通用GPT
      if (value== 1) {
        this.$nextTick(() => {
          this.position2();
        });
      }
      else if (value == 2) {
        this.$nextTick(() => {
          this.position7();
        });
      }
      else if (value == 3) {
        this.$nextTick(() => {
          this.position6();
        });
      }
      else if (value == 4) {
        this.$nextTick(() => {
          this.position3();
        });
      }
      else if (value == 0) {
        this.$nextTick(() => {
          this.uptop();
        });
      }
    },
    hideDialog() {
      localStorage.setItem("skip", 0)
      this.dialogVisible = false;
    },
    hide() {
      localStorage.setItem("skip", 0)
      this.dialogconsult = false;
    },
    expo() {
      localStorage.setItem("skip", 0)
      window.open("https://cn.oilgasmall.com/", "_blank");
    },
    job() {
      localStorage.setItem("skip", 0)
      window.open("https://oilgasjobai.com/", "_blank");
    },
    info() {
      localStorage.setItem("skip", 0)
      window.open("https://cn.oilgasinfoai.com/", "_blank");
    },
    wen() {
      localStorage.setItem("skip", 0)
      window.open("https://yiyan.baidu.com/", "_blank");
    },
    dou() {
      localStorage.setItem("skip", 0)
      window.open("https://www.doubao.com/", "_blank");
    },
    xun() {
      localStorage.setItem("skip", 0)
      window.open("https://xinghuo.xfyun.cn/", "_blank");
    },
    tong() {
      localStorage.setItem("skip", 0)
      window.open("https://tongyi.aliyun.com/qianwen/", "_blank");
    },
    zhi() {
      localStorage.setItem("skip", 0)
      window.open("https://chatglm.cn/", "_blank");
    },
    teng() {
      localStorage.setItem("skip", 0)
      window.open("https://hunyuan.tencent.com/", "_blank");
    },
    tian() {
      localStorage.setItem("skip", 0)
      window.open("https://home.tiangong.cn/", "_blank");
    },
    ge() {
      localStorage.setItem("skip", 0)
      window.open("https://yige.baidu.com/", "_blank");
    },
    wan() {
      localStorage.setItem("skip", 0)
      window.open("https://ai.kezhan365.com/", "_blank");
    },
    yi() {
      localStorage.setItem("skip", 0)
      window.open("https://aigc.yizhentv.com/", "_blank");
    },


    expo1() {
      sessionStorage.setItem("skip", 2)
      // 在 Vue 组件或路由导航守卫中使用 router.push() 导航到外部链接
      const externalLink = 'https://cn.oilgasmall.com/';
      // 导航到外部链接处理路由，并传递外部链接作为参数
      this.$router.push({name: 'ExternalLinkHandler', params: {url: externalLink}});
    },
    job1() {
      sessionStorage.setItem("skip", 2)
      const externalLink = 'https://oilgasjobai.com/';
      // 导航到外部链接处理路由，并传递外部链接作为参数
      this.$router.push({name: 'ExternalLinkHandler', params: {url: externalLink}});

    },
    info1() {
      sessionStorage.setItem("skip", 2)
      const externalLink = 'https://cn.oilgasinfoai.com/';
      // 导航到外部链接处理路由，并传递外部链接作为参数
      this.$router.push({name: 'ExternalLinkHandler', params: {url: externalLink}});

    },
    wen1() {
      sessionStorage.setItem("skip", 1)
      const externalLink = 'https://yiyan.baidu.com/';
      // 导航到外部链接处理路由，并传递外部链接作为参数
      this.$router.push({name: 'ExternalLinkHandler', params: {url: externalLink}});

    },
    traningAi1(){
      sessionStorage.setItem("skip", 12)
      this.$router.push("/traning")
    },
    consult1() {
      sessionStorage.setItem("skip", 12)
      this.$router.push("/consult")
    },
    constructPlat1(){
      sessionStorage.setItem("skip", 13)
      this.$router.push("/construct")

    },
    business1() {
      sessionStorage.setItem("skip", 13)
      this.$router.push("/business")
    },
    company1() {
      sessionStorage.setItem("skip", 13)
      this.$router.push("/company")
    },
    computingPower1() {
      sessionStorage.setItem("skip", 13)
      this.$router.push("/computingPower")
    },
    inter(){
      window.open("/inter")
    },
    position2(){
      if(this.model===false){
        const element = this.$refs.generalMove;
        const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
        // eslint-disable-next-line no-inner-declarations
        function scrollToElement(element, to, duration) {
          if (duration <= 0) return;
          const difference = to - element.scrollTop;
          const perTick = difference / duration * 10;
          setTimeout(() => {
            element.scrollTop = element.scrollTop + perTick;
            if (element.scrollTop === to) return;
            scrollToElement(element, to, duration - 10);
          }, 10);
        }

        scrollToElement(this.$refs.total, rect, 500); // 滚动持续时间为500毫秒
      }
      else{
        console.log("1545")
        const element = this.$refs.generalMoveMobile;
        const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
        // eslint-disable-next-line no-inner-declarations
        function scrollToElement(element, to, duration) {
          if (duration <= 0) return;
          const difference = to - element.scrollTop;
          const perTick = difference / duration * 10;
          setTimeout(() => {
            element.scrollTop = element.scrollTop + perTick;
            if (element.scrollTop === to) return;
            scrollToElement(element, to, duration - 10);
          }, 10);
        }

        scrollToElement(this.$refs.totalModel, rect, 500); // 滚动持续时间为500毫秒
      }
    },
    position3(){
      if(this.model===false) {
        const element = this.$refs.aiPeixun;
        const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);


        // eslint-disable-next-line no-inner-declarations
        function scrollToElement(element, to, duration) {
          if (duration <= 0) return;
          const difference = to - element.scrollTop;
          const perTick = difference / duration * 10;
          setTimeout(() => {
            element.scrollTop = element.scrollTop + perTick;
            if (element.scrollTop === to) return;
            scrollToElement(element, to, duration - 10);
          }, 10);
        }

        scrollToElement(this.$refs.total, rect, 500); // 滚动持续时间为500毫秒
      }
      else{
        const element = this.$refs.aiPeixunMobile;
        const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);


        // eslint-disable-next-line no-inner-declarations
        function scrollToElement(element, to, duration) {
          if (duration <= 0) return;
          const difference = to - element.scrollTop;
          const perTick = difference / duration * 10;
          setTimeout(() => {
            element.scrollTop = element.scrollTop + perTick;
            if (element.scrollTop === to) return;
            scrollToElement(element, to, duration - 10);
          }, 10);
        }

        scrollToElement(this.$refs.totalModel, rect, 500); // 滚动持续时间为500毫秒
      }
    },
    position6(){
      if(this.model===false) {
        const element = this.$refs.consultMove;
        const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
        // eslint-disable-next-line no-inner-declarations
        function scrollToElement(element, to, duration) {
          if (duration <= 0) return;
          const difference = to - element.scrollTop;
          const perTick = difference / duration * 10;
          setTimeout(() => {
            element.scrollTop = element.scrollTop + perTick;
            if (element.scrollTop === to) return;
            scrollToElement(element, to, duration - 10);
          }, 10);
        }

        scrollToElement(this.$refs.total, rect, 500); // 滚动持续时间为500毫秒
      }
      else{
        const element = this.$refs.consultMoveMobile;
        const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
        // eslint-disable-next-line no-inner-declarations
        function scrollToElement(element, to, duration) {
          if (duration <= 0) return;
          const difference = to - element.scrollTop;
          const perTick = difference / duration * 10;
          setTimeout(() => {
            element.scrollTop = element.scrollTop + perTick;
            if (element.scrollTop === to) return;
            scrollToElement(element, to, duration - 10);
          }, 10);
        }

        scrollToElement(this.$refs.totalModel, rect, 500); // 滚动持续时间为500毫秒
      }

    },
    position7(){
      if(this.model===false) {
        const element = this.$refs.professional;
        const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

        // eslint-disable-next-line no-inner-declarations
        function scrollToElement(element, to, duration) {
          if (duration <= 0) return;
          const difference = to - element.scrollTop;
          const perTick = difference / duration * 10;
          setTimeout(() => {
            element.scrollTop = element.scrollTop + perTick;
            if (element.scrollTop === to) return;
            scrollToElement(element, to, duration - 10);
          }, 10);
        }

        scrollToElement(this.$refs.total, rect, 500); // 滚动持续时间为500毫秒
      }
      else{
        const element = this.$refs.professionalMobile;
        const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

        // eslint-disable-next-line no-inner-declarations
        function scrollToElement(element, to, duration) {
          if (duration <= 0) return;
          const difference = to - element.scrollTop;
          const perTick = difference / duration * 10;
          setTimeout(() => {
            element.scrollTop = element.scrollTop + perTick;
            if (element.scrollTop === to) return;
            scrollToElement(element, to, duration - 10);
          }, 10);
        }

        scrollToElement(this.$refs.totalModel, rect, 500); // 滚动持续时间为500毫秒
      }

    },
    position8(){
      if(this.model===false) {
        const element = this.$refs.generalAuto;
        const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

        // eslint-disable-next-line no-inner-declarations
        function scrollToElement(element, to, duration) {
          if (duration <= 0) return;
          const difference = to - element.scrollTop;
          const perTick = difference / duration * 10;
          setTimeout(() => {
            element.scrollTop = element.scrollTop + perTick;
            if (element.scrollTop === to) return;
            scrollToElement(element, to, duration - 10);
          }, 10);
        }

        scrollToElement(this.$refs.total, rect, 500); // 滚动持续时间为500毫秒
      }
      else{
        const element = this.$refs.professionalMobile;
        const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

        // eslint-disable-next-line no-inner-declarations
        function scrollToElement(element, to, duration) {
          if (duration <= 0) return;
          const difference = to - element.scrollTop;
          const perTick = difference / duration * 10;
          setTimeout(() => {
            element.scrollTop = element.scrollTop + perTick;
            if (element.scrollTop === to) return;
            scrollToElement(element, to, duration - 10);
          }, 10);
        }

        scrollToElement(this.$refs.totalModel, rect, 500); // 滚动持续时间为500毫秒
      }

    },
    skipp1 () {
      const element = this.$refs.general;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);


      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },
    skipp2() {
      const element = this.$refs.zixun;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },
    skipp3() {
      const element = this.$refs.bottom;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },
    skipp4() {
      const element = this.$refs.professional;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

      this.$nextTick(() => {
        this.$refs.total.scrollTop = rect
      });
    },
    skipp5() {
      const element = this.$refs.professional1;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },
    skipp6() {
      const element = this.$refs.upload;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },
    skipp7() {
      const element = this.$refs.station;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },
    skipp8() {
      const element = this.$refs.technology;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },
    skipp10() {
      const element = this.$refs.menageServices;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },
    uptop(){

      if(this.model===false){
        const element = this.$refs.total;
        const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

        this.$nextTick(() => {
          this.$refs.total.scrollTop = rect
        });
      }else{
        const elementModel = this.$refs.totalModel;
        const rect = elementModel.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

        this.$nextTick(() => {
          this.$refs.totalModel.scrollTop = rect
        });
      }


    },
    skipp11() {
      const element = this.$refs.company;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },
    skipp12() {
      const element = this.$refs.traningConsult;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },
    skipp13() {
      const element = this.$refs.companyConduct;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },
    skipp14() {
      const element = this.$refs.media;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },
    dou1() {
      sessionStorage.setItem("skip", 1)
      const externalLink = 'https://www.doubao.com/';
      this.$router.replace({name: 'ExternalLinkHandler', params: {url: externalLink}});
    },
    xun1() {
      sessionStorage.setItem("skip", 1)
      const externalLink = 'https://xinghuo.xfyun.cn/';
      // 导航到外部链接处理路由，并传递外部链接作为参数
      this.$router.push({name: 'ExternalLinkHandler', params: {url: externalLink}});

    },
    tong1() {
      sessionStorage.setItem("skip", 1)
      const externalLink = 'https://tongyi.aliyun.com/qianwen/';
      // 导航到外部链接处理路由，并传递外部链接作为参数
      this.$router.push({name: 'ExternalLinkHandler', params: {url: externalLink}});

    },
    zhi1() {
      sessionStorage.setItem("skip", 1)
      const externalLink = 'https://chatglm.cn/';
      // 导航到外部链接处理路由，并传递外部链接作为参数
      this.$router.push({name: 'ExternalLinkHandler', params: {url: externalLink}});
    },
    teng1() {
      sessionStorage.setItem("skip", 1)
      const externalLink = 'https://hunyuan.tencent.com/';
      // 导航到外部链接处理路由，并传递外部链接作为参数
      this.$router.push({name: 'ExternalLinkHandler', params: {url: externalLink}});
    },
    tian1() {
      sessionStorage.setItem("skip", 1)
      const externalLink = 'https://home.tiangong.cn/';
      // 导航到外部链接处理路由，并传递外部链接作为参数
      this.$router.push({name: 'ExternalLinkHandler', params: {url: externalLink}});
    },
    ge1() {
      sessionStorage.setItem("skip", 1)
      const externalLink = 'https://yige.baidu.com/';
      // 导航到外部链接处理路由，并传递外部链接作为参数
      this.$router.push({name: 'ExternalLinkHandler', params: {url: externalLink}});
    },
    wan1() {
      sessionStorage.setItem("skip", 1)
      const externalLink = 'https://ai.kezhan365.com/';
      // 导航到外部链接处理路由，并传递外部链接作为参数
      this.$router.push({name: 'ExternalLinkHandler', params: {url: externalLink}});
    },
    yi1() {
      sessionStorage.setItem("skip", 1)
      const externalLink = 'https://aigc.yizhentv.com/';
      // 导航到外部链接处理路由，并传递外部链接作为参数
      this.$router.push({name: 'ExternalLinkHandler', params: {url: externalLink}});
    },
    meitiProduct1(){
      sessionStorage.setItem("skip", 14)
      const externalLink = 'http://www.oilmates-omm.com/h-col-106.html';
      // 导航到外部链接处理路由，并传递外部链接作为参数
      this.$router.push({name: 'ExternalLinkHandler', params: {url: externalLink}});

    },
    meiti3D1(){
      sessionStorage.setItem("skip", 14)
      const externalLink = 'http://www.oilmates-omm.com/h-col-102.html';
      // 导航到外部链接处理路由，并传递外部链接作为参数
      this.$router.push({name: 'ExternalLinkHandler', params: {url: externalLink}});

        },
    meitiDesign1(){
      sessionStorage.setItem("skip", 14)
      const externalLink = 'http://www.oilmates-omm.com/h-col-104.html';
      // 导航到外部链接处理路由，并传递外部链接作为参数
      this.$router.push({name: 'ExternalLinkHandler', params: {url: externalLink}});

    },
    updateGroupedItems() {
      const startIndex = this.currentIndex;
      this.groupedItems = [];

      for (let i = 0; i < this.itemsPerPage; i++) {
        const group = this.carouselItems.slice(
            startIndex + i,
            startIndex + i + this.itemsPerPage
        );
        this.groupedItems.push(group);
      }
    },
    leftnext() {
      console.log(this.currentIndex);
      if (this.currentIndex > 0) {
        this.currentIndex--;
        this.updateGroupedItems();
      }
    },
    rightnext() {
      if (this.currentIndex < 1) {
        this.currentIndex++;
        this.updateGroupedItems();
      }
    },
    onCarouselChange(index) {
      this.currentIndex = index;
      this.updateGroupedItems();
    },
  },
};
</script>
<style lang="scss" scoped>
.avator {
  border: 2px solid #fff;
}

.userName {
  color: #2f6eff;
  font-size: 16px;
  line-height: 1.3;
   ;
  margin-left: 10px;
  max-width: 200px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.total {
  background: #bed8f9;
  overflow-x: hidden;
  width: 100vw;
  height: 100vh;
  position: relative;

  .screen1 {
    z-index: 3;
    position: relative;
    width: 100%;
    height:100vh;
    padding: 2.84301606922126vh 2.86077411900635vw 2.84301606922126vh 2.86077411900635vw;
    background-image: url("../assets/back1.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: transparent;
    /* Add any additional styling as needed */

    & > video{
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 0;
      object-fit: cover;
    }
    & ::v-deep .layer1{
      position: relative;
      z-index: 1;
    }

    .up{
      cursor: pointer;
      position: fixed;
      top:90%;
      right:2%;
      padding:5px;
      width: 2.5vw;
      height:2.5vw;
      border-radius: 50%;
      //border: 5px solid #1295d9;
      background-image: url("../assets/up.png");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      background-color: transparent;
      z-index: 10;
    }
    .layer2 {
      margin-top: 4vh;
      text-align: center;
      position: relative;
      z-index: 1;
      .title1 {

        height: 8.77626699629172vh;
        font-size: 3vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight:bold;
        color: white;
        line-height: 12.23733003708282vh;
      }


      .title3 {
        display: flex;
        text-align: center;
        margin: 30vh auto 0 auto;
        width: fit-content;
        height: 8.52904820766378vh;
        font-size: 1.5vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: bold;
        color: white;
        line-height: 5.31520395550062vh;
        .hoverTitleup{
          display: flex;
          margin-right: 5vw;
          cursor: pointer;
          transition:transform 0.3s ease-out;

        }
        .hoverTitleup:hover{
          transform:translateY(-5px);
        }

        .hoverTitle{
          display: flex;
          margin-right: 2vw;
          cursor: pointer;
          transition:transform 0.3s ease-out;

        }
        .hoverTitle:hover{
          transform:translateY(-5px);
        }
      }

    }

    //.layer3 {
    //  position: absolute;
    //  top: 5.06798516687268vh;
    //  right: 11.61178509532062vw;
    //  width: 47.42923165800116vw;
    //  height: 72.80593325092707vh;
    //  background-image: url("../assets/GPTplant.png");
    //  background-size: 100% auto;
    //  background-position: center;
    //  background-repeat: no-repeat;
    //  background-color: transparent;
    //  opacity: 1;
    //}
  }

  .screenConsultnew {
    z-index: 10;
    width: 100vw;
    height: fit-content;
    border-radius: 9px;
    padding: 7.7873918418vh 11.0976314269vw 7.7873918418vh 8.0976314269vw;
    background: white;

    .title {

      height: 8.23733003708282vh;
      opacity: 1;
      font-size: 2vw;
      color:   #3172dd;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 700;
      line-height: 4.23733003708282vh;
    }

    .light {
      margin: 0 auto 4vh auto;
      width: 70vw;
      height: fit-content;
      font-size: 1.0287117273vw;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 400;
      color:   #3172dd;
      line-height: 4.94437577255871vh;

    }


    .pro3 {
      //margin-top: -4vh;
      height: fit-content;
      width: 100%;
      z-index: 89156;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      column-gap: 0;
      row-gap: 2vh;

      .grand2 {
        cursor: pointer;
        width: 100%;
        height:fit-content;
        padding: 0.98887515451174vh 0.52432120161756vw 0.98887515451174vh 0.39324090121317vw;
        opacity: 1;
        position: relative;

        .back {
          position: relative;
          transition: transform 0.5s ease;
          width: 100%;
          height: 40vh;
          background: #D4E4FF;
          // box-shadow: 5px 0 14px 0px #D4E4FF;
          // border: 2px solid #FFFFFF;
          border-radius: 9px;
        }


        .photo {
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          background-color: #fff; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          margin: 0 auto auto auto;
          display: flex;
          justify-content: center;
          text-align: center;
          width: 100%;
          height: 30vh;
        }


        .title1 {
          opacity: 4;
          margin-top: 3vh;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 2.71940667490729vh;
          font-size: 1.17094165222415vw;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: bold;
          color: #2168DB;
          line-height: 3.5vh;
          padding: 0 .5vw;
        }

        .title2 {
          opacity: 4;
          margin: 1.94437577255871vh 1.35540150202195vw 2.94437577255871vh 1.35540150202195vw;
        ;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 0.80878105141537vw;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 500;
          //color: #2168DB;
          line-height: 2.71940667490729vh;
        }

        .consult {
          position: absolute;
          display: none;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 500;
          font-size: 0.80878105141537vw;
          bottom: 3.94437577255871vh;
          left: 1.85540150202195vw;
        }
      }

      .grand2:hover {
        .back {
          transform: translateY(-10px);

        }

      }
    }



  }

  .screenGpts {
    z-index: 10;
    width: 100vw;
    height: fit-content;
    border-radius: 9px;
    padding: 7.78739184177998vh 11.09763142692085vw 7.78739184177998vh 8.09763142692085vw;
    background: transparent;


    .title {

      height: 8.23733003708282vh;
      opacity: 1;
      font-size: 2vw;
      color: #356ff8;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 700;
      line-height: 4.23733003708282vh;
    }

    .light {
      margin: 0 auto 4vh auto;
      //text-align: justify;
      width: 66vw;
      height: fit-content;
      font-size: 1.0287117273vw;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 400;
      color: #3172dd;
      line-height: 4.94437577255871vh;

    }


    .pro3 {
      //margin-top: -4vh;
      height: fit-content;
      width: 100%;
      z-index: 89156;
      display: grid;
      grid-template-columns:minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
      column-gap: 0;
      row-gap: 2vh;

      .grand2 {
        cursor: pointer;
        width: 100%;

        height:fit-content;
        padding: 0.98887515451174vh 0.52432120161756vw 0.98887515451174vh 0.39324090121317vw;
        opacity: 1;

        .back {
          transition: transform 0.5s ease;
          width: 100%;
          position: relative;
          height: 100%;
          background: white;
          box-shadow: 5px 0 14px 0px #D4E4FF;
          border: 2px solid #FFFFFF;
          border-radius: 9px;
        }
        .photo {
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          background-color: #fff; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          margin: 0 auto auto auto;
          display: flex;
          justify-content: center;
          text-align: center;
          width: 100%;
          height: 30vh;
        }


        .title1 {
          padding: 3vh 0;
          opacity: 4;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 14.71940667490729vh;
          font-size: 1.17094165222415vw;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: bold;
          color: #2168DB;
          line-height: 3.5vh;
          padding: 5vh .5vw;
        }

        .title2 {
          opacity: 4;
          margin: 1.94437577255871vh 1.35540150202195vw 2.94437577255871vh 1.35540150202195vw;
          //text-align: justify;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size:0.9vw;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 500;
          //color: #2168DB;
          line-height: 2.71940667490729vh;
        }

        .consult {
          position: absolute;
          display: none;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 500;
          font-size:0.9vw;
          bottom: 3.94437577255871vh;
          left: 1.85540150202195vw;
        }
      }

      .grand2:hover {
        .back {
          transform: translateY(-10px);

        }

      }
    }



  }

  .screen3 {
    z-index: 10;
    width: 100vw;
    height: fit-content;
    border-radius: 9px;
    padding: 7.78739184177998vh 11.09763142692085vw 7.78739184177998vh 8.09763142692085vw;
    background: white;


    .title {

      height: 8.23733003708282vh;
      opacity: 1;
      text-align: center;
      margin:0 auto;
      font-size: 2vw;
      color: #356ff8;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 700;
      line-height: 4.23733003708282vh;
    }

    .light {
      margin: 0 auto 4vh auto;
      //text-align: justify;
      width: 66vw;
      height: fit-content;
      font-size: 1.0287117273vw;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 400;
      color: #3172dd;
      line-height: 4.94437577255871vh;

    }


    .pro2 {

      border-radius: 9px;
      height: fit-content;
      width: 100%;
      z-index: 89156;
      display: grid;
      grid-template-columns:minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
      column-gap: 0;

      .grand2 {
        border-radius: 9px;
        cursor: pointer;
        width: 100%;
        min-height: 30.65636588380717vh;
        padding: 0.98887515451174vh 0.5vw 0.98887515451174vh 0.5vw;
        opacity: 1;
        position: relative;

        .back {
          transition: transform 0.5s ease;
          width: 100%;
          height: 100%;
          //background: #D4E4FF;;
          //box-shadow: 5px 0 14px 0px #D4E4FF;
          border: 2px solid #FFFFFF;
          border-radius: 9px;
        }

        .photo {

          border-radius: 9px;
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          background-color: transparent; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          border: 9px;
          display: flex;
          justify-content: center;
          text-align: center;
          width: 100%;
          height: fit-content;
        }

        .title1 {
          position: absolute;
          left: 4vh;
          top:5vh;
          opacity: 4;
          text-align: left;
          display: flex;
          height: 2.71940667490729vh;
          font-size: 1.17094165222415vw;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: bold;
          color: #2168DB;
          line-height: 3vh;
        }



      }

      .grand2:hover {
        .back {
          .photo {
            transform: scale(1.05);
          }
        }
      }
    }


  }
  .screenConsult1 {
    z-index: 10;
    width: 100vw;
    height: fit-content;
    border-radius: 9px;
    padding: 7.78739184177998vh 11.09763142692085vw 7.78739184177998vh 8.09763142692085vw;
    background: transparent;


    .title {

      height: 8.23733003708282vh;
      opacity: 1;
      font-size: 2vw;
      color: #356ff8;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 700;
      line-height: 4.23733003708282vh;
    }

    .light {
      margin: 0 auto 4vh auto;

      width: 66vw;
      height: fit-content;
      font-size: 1.0287117273vw;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 400;
      color: #3172dd;
      line-height: 4.94437577255871vh;

    }

    .pro2 {
      height: fit-content;
      width: 100%;
      z-index: 89156;
      display: grid;
      grid-template-columns:minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
      column-gap: 0;
      row-gap:0;

      .grand1 {
        transition: transform 0.5s ease;
        cursor: pointer;
        width: 100%;
        min-height: fit-content;
        //padding: 0.98887515451174vh 0.52432120161756vw 0.98887515451174vh 0.19324090121317vw;
        opacity: 1;
        position: relative;

        .back {
          transition: transform 0.5s ease;
          width: 100%;
          height: 100%;
          min-height: 20vh;
          //background: white;;
          //box-shadow: 5px 0 14px 0px #D4E4FF;
          //border: 2px solid #FFFFFF;
          border-radius: 9px;
          display: flex;
        }

        .photo {
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          background-color: transparent; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          margin: 3vh 0 2vh 0.8vw;
          display: flex;
          justify-content: center;
          text-align: center;
          width: 100%;
          border-radius: 9px;
          height:fit-content;
        }

        .titleAligen{
          position: absolute;
          left:1.5vw;
          top:3vh;

          width: 90%;
        }

        .title1 {
          opacity: 4;
          margin: 3.22496909765142vh 0.85540150202195vw 1.22496909765142vh 0.85540150202195vw;;
          width: 23vw;
          height: 3vh;
          font-size: 1vw;
          white-space: nowrap; //换行
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: bold;
          overflow: hidden;
          text-overflow: ellipsis;
          //color: #2168DB;
          line-height: 2.71940667490729vh;
        }
        .title2 {
          opacity: 4;
          margin: 0.94437577255871vh 0.85540150202195vw 0.94437577255871vh 1.54437577255871vh;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 4; /* 限制在一个块元素显示的文本的行数 */
          overflow: hidden;
          text-overflow: ellipsis;
          font-size:0.9vw;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 500;
          //color: #2168DB;
          line-height: 3.5vh;
          height: fit-content;
        }

        .consult {
          display: none; /* Hide by default */
          position: absolute;
          left: -100%; /* Start off-screen to the left */
          transition: left 5s ease; /* Animate the left property */
          padding: 2.5vh 1.5vw;
          color: white;
          border-bottom-left-radius: 9px;
          border-bottom-right-radius: 9px;
          height: 8vh;
          width: 95.5%;
          background: url("../assets/froundHover.png");
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          font-family: Arial, sans-serif, "Microsoft Yahei"; /* Corrected typo 'Sarial' */
          font-weight: 500;
          font-size:0.9vw;
          bottom: 1vh;

        }
      }

      .grand1:hover    .consult {
        display: block; /* Show on hover */
        left: 1vw; /* Slide into view */

      }

    }


  }
  .screen8 {
    width: 100%;
    height: fit-content;
    padding: 7.78739184177998vh 0;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-color: transparent; /* 这里设置背景颜色为透明 */
    z-index: 10;
    //left: 10.86077411900635vw;
    border-radius: 0;

    .title {
      margin: 0 auto;
      text-align: center;
      height: 8.23733003708282vh;
      opacity: 1;
      font-size: 2.25vw;
      color:   #3172dd;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 700;
      line-height: 4.23733003708282vh;
    }

    .light {
      margin: 0 auto 4vh auto;
      text-align:left;
      width: 70vw;
      height: fit-content;
      font-size: 1.0287117273vw;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 400;
      color:   #3172dd;
      line-height: 4.94437577255871vh;
    }
    .light2 {
      margin: 0 auto 0 auto;
      text-align: center;
      width: 70vw;
      font-size: 1.0287117273vw;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 400;
      color: #FF6400;
      line-height: 4.94437577255871vh;
    }

    .pro2 {
      transition: transform 0.5s ease;
      height: fit-content;
      width: 100%;
      z-index: 89156;
      padding: 0 8.09763142692085vw 0 8.09763142692085vw;
      display: grid;
      grid-template-columns:minmax(0, 1fr);
      //column-gap: 1vw;
      row-gap: 2vh;
    }
    .pro2:hover {
      transform: translateY(-10px);
    }

  }
  ::v-deep .el-tooltip__content {
    font-size: 1vw !important;  /* 你想要的字体大小 */
  }


}

.totalModel {
  background:#bed8f9;
  overflow-x: hidden;
  width: 100vw;
  height: 100vh;
  position: relative;
  .up{
    cursor: pointer;
    position: fixed;
    top:90%;
    right:3%;
    padding:5px;
    width: 45px;
    height:45px;
    z-index: 999999999;
    border-radius: 50%;
    //border: 5px solid #1295d9;
    background-image: url("../assets/up.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: transparent;
  }
  .screen1 {
    z-index: 3;
    position: relative;
    width: 100%;
    height: 400px;
    padding: 30px 4.6875vw;
    background-image: url("../assets/back1.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: transparent;
    /* Add any additional styling as needed */

    & > video{
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 0;
      object-fit: cover;
    }
    & ::v-deep .layer2,& ::v-deep .layerModel,& ::v-deep .leftLayer{
      position: relative;
      z-index: 1;
    }

    .layer2 {
      .title1 {
        margin: 0 auto;
        width: fit-content;
        height: 23px;
        font-size: 23px;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 500;
        color: #111111;
        line-height: 8.23733003708282vh;
      }

      .title2 {
        margin: 35px auto 0 auto;
        width: fit-content;
        height: 17px;
        font-size: 14px;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #111111;
        line-height: 24px;
      }

      .title3 {
        display: flex;
        text-align: center;
        margin: 20px auto 0 auto;
        width: fit-content;
        height: 35px;
        font-size: 8px;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: bold;
        color: #2f6eff;
        line-height: 22px;
      }
    }

    .layer3 {
      position: absolute;
      display: flex;
      bottom: 15px;
      opacity: 1;
      width: 90%;
      justify-content: center;

      .communicity {
        z-index: 8;
        cursor: pointer;
        font-size: 14px;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #2f6eff;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center; /* Vertically center the content */
        border-radius: 53px;
        width: fit-content;
        margin: 0 15px;
        padding: 10px 4.6875vw;
        height: 35px;
        border: #2f6eff 1px solid;
        overflow: hidden; /* Add overflow property */
      }

      .communicityGpt {
        z-index: 8;
        cursor: pointer;
        font-size: 14px;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: white;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center; /* Vertically center the content */
        border-radius: 53px;
        width: fit-content;
        margin: 0 15px;
        padding: 10px 4.6875vw;
        height: 35px;
        background-color: #2f6eff;
        border: #2f6eff 1px solid;
        overflow: hidden; /* Add overflow property */
      }

    }
  }

  .screenConsultnew {
    z-index: 10;
    width: 100vw;
    height: fit-content;
    //border-radius: 9px;
    padding: 25px 20px;
    background: white;

    .title {
      text-align: center;
      margin:0 auto;
      height: fit-content;
      opacity: 1;
      font-size: 16px;
      //color:   #3172dd;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 700;
      line-height: 30px;
    }

    .light {
      margin: 15px auto 0 auto;
       ;
      width: 90%;
      height: fit-content;
      font-size: 14px;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 400;
      color: #2C3E50;
      line-height: 22px;
    }


    .pro3 {
      //margin-top: -4vh;
      height: fit-content;
      width: 100%;
      z-index: 89156;
      display: grid;
      grid-template-columns: minmax(0, 1fr);
      column-gap: 15px;
      row-gap:0;

      .grand2 {
        cursor: pointer;
        width: 100%;
        height:fit-content;
        padding:15px 0;
        opacity: 1;
        position: relative;

        .back {
          transition: transform 0.5s ease;
          width: 100%;
          height: 100%;
          background: #D4E4FF;
          box-shadow: 5px 0 14px 0px #D4E4FF;
          border: 2px solid #FFFFFF;
          border-radius: 9px;
        }


        .photo {
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          background-color: #fff; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          margin: 0 auto auto auto;
          display: flex;
          justify-content: center;
          text-align: center;
          width: 100%;
          height: 34vh;
        }


        .title1 {
          opacity: 4;
          margin:15px auto;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          height: fit-content;
          font-size: 14px;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: bold;
          //color: #2168DB;
          line-height: 22px;
        }


        .consult {
          position: absolute;
          display: none;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 500;
          font-size: 0.80878105141537vw;
          bottom: 3.94437577255871vh;
          left: 1.85540150202195vw;
        }
      }


    }



  }
  .screen3 {
    z-index: 10;
    width: 100vw;
    height: fit-content;
    //border-radius: 9px;
    padding: 25px 20px;
    background: white;

    .title {

      height: fit-content;
      opacity: 1;
      text-align: center;
      margin:0 auto;
      font-size: 16px;
      //color:   #3172dd;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 700;
      line-height: 30px;
    }

    .light {
      margin: 15px auto 0 auto;
      width: 90%;
      height: fit-content;
      font-size: 14px;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 400;
      color: #2C3E50;
      line-height: 22px;

    }



    .pro2 {

      border-radius: 9px;
      height: fit-content;
      width: 100%;
      z-index: 89156;
      display: grid;
      grid-template-columns:minmax(0, 1fr);
      column-gap: 0;

      .grand2 {
        border-radius: 9px;
        cursor: pointer;
        width: 100%;
        padding: 15px 0;
        opacity: 1;
        position: relative;

        .back {
          transition: transform 0.5s ease;
          width: 100%;
          height: 100%;
          background: #D4E4FF;;
          box-shadow: 5px 0 14px 0px #D4E4FF;
          border: 2px solid #FFFFFF;
          border-radius: 9px;
        }

        .photo {
          border-radius: 9px;
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          background-color: transparent; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          display: flex;
          justify-content: center;
          text-align: center;
          width: 100%;
          height: fit-content;
        }

        .title1 {
          opacity: 4;
          position: absolute;
          top:20px;
          margin: 15px auto;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          height: fit-content;
          font-size: 14px;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: bold;
          //color: #2168DB;
          line-height: 22px;
        }

        .title2 {
          opacity: 4;
          margin: 1.94437577255871vh 1.35540150202195vw 2.94437577255871vh 1.35540150202195vw;
           ;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 12px;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 500;
          //color: #2168DB;
          line-height: 2.71940667490729vh;
        }


      }

    }


  }
  .screenConsult1 {
    z-index: 10;
    width: 100vw;
    height: fit-content;
    //border-radius: 9px;
    padding: 25px 20px;
    background: transparent;

    .title {
      text-align: center;
      margin:0 auto;
      height: fit-content;
      opacity: 1;
      font-size: 16px;
      //color:   #3172dd;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 700;
      line-height: 30px;
    }

    .light {
      margin: 15px auto 0 auto;
    ;
      width: 90%;
      height: fit-content;
      font-size: 14px;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 400;
      color: #2C3E50;
      line-height: 22px;

    }


    .pro2 {
      height: fit-content;
      width: 100%;
      z-index: 89156;
      display: grid;
      grid-template-columns:minmax(0, 1fr);
      column-gap: 0;
      row-gap:0;

      .grand1 {
        transition: transform 0.5s ease;
        cursor: pointer;
        width: 100%;
        min-height: fit-content;
        padding: 15px 0 0 0;
        opacity: 1;
        position: relative;

        .back {
          transition: transform 0.5s ease;
          height: 100%;
          background: white;;
          box-shadow: 5px 0 14px 0px #D4E4FF;
          border: 2px solid #FFFFFF;
          border-radius: 9px;
          display: flex;
          width: 100%;

          .title1 {
            opacity: 4;
            margin: 15px 15px 5px 15px;
            width: 80%;
            height: fit-content;
            font-size: 14px;
            white-space: nowrap; //换行
            font-family: Sarial, sans-serif, "Microsoft Yahei";
            font-weight: bold;
            overflow: hidden;
            text-overflow: ellipsis;
            //color: #2168DB;
            line-height: 22px;
          }

          .title2 {
            //width: 165px;
            opacity: 4;
            margin:0 15px 15px 15px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 4; /* 限制在一个块元素显示的文本的行数 */
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 12px;
            font-family: Sarial, sans-serif, "Microsoft Yahei";
            font-weight: 500;
            //color: #2168DB;
            line-height: 22px;
            height: 88px;
          }
        }

        .photo {
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          background-color: transparent; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          margin: 15px 0 0 15px;
          display: flex;
          justify-content: center;
          text-align: center;
          width: 125px;
          border-radius: 9px;
          height: 125px;
        }




        .consult {
          transition: transform 0.5s ease;
          position: absolute;
          display: none;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 500;
          font-size:0.80878105141537vw;
          bottom: 3.94437577255871vh;
        }
      }



    }


  }
  .screen8 {
    z-index: 10;
    width: 100vw;
    height: fit-content;
    //border-radius: 9px;
    padding: 25px 20px;
    background: transparent;

    .title {
      text-align: center;
      margin:0 auto;
      height: fit-content;
      opacity: 1;
      font-size: 16px;
      //color:   #3172dd;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 700;
      line-height: 30px;
    }

    .light {
      margin: 15px auto 0 auto;
    ;
      width: 90%;
      height: fit-content;
      font-size: 14px;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 400;
      color: #2C3E50;
      line-height: 22px;

    }

    .pro2 {
      transition: transform 0.5s ease;
      height: fit-content;
      width: 100%;
      z-index: 89156;
      padding: 15px;
      display: grid;
      grid-template-columns:minmax(0, 1fr);
      //column-gap: 1vw;
    }


  }

}
</style>

